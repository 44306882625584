import {Link} from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import Table from './Main/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faBell } from '@fortawesome/free-solid-svg-icons';

import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import Context from './Context';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OverlaySpinner from '../OverlaySpinner';

const AssessmentId = () => {
	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);
	const { id } = useParams();
	const [returnData, setReturnData] = useState();

	const [ notify, setNotify ] = useState();
	const [ spinner, setSpinner ] = useState(false);

	const getInc = async() => {
		window.scrollTo(0, 0);
		const res = await fetch(`https://api.te-amo.co.za/public/api/assessments/unit/${id}`)
		const data = await res.json();
		console.log(data);
		setReturnData(data);

		const res2 = await fetch('https://api.te-amo.co.za/public/api/enquiry/read', {
		  method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'id': id}),
		})

		const res3 = await fetch(`https://api.te-amo.co.za/public/api/notify/get?id=${main}`);
     	const data3 = await res3.json();

     	setNotify(data3);
	}

	const makeJobeCard = async() => {
			setSpinner(true);
			const res3 = await fetch(`https://api.te-amo.co.za/public/api/customers/${returnData?.Customer_id}`);
     	const data3 = await res3.json();

     	console.log(data3);

			const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/add`, {
					method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'name': data3[0].Name, 'job': returnData?.Description, 'admin': main, 'main': main, 'customer_id': returnData?.Customer_id, 'type': 'Call out', 'dscr_job':returnData?.Description }),
			});
			setSpinner(false);
			toast.success('Job card created successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
	}

	const deleteJobeCard = async() => {
			setSpinner(true);
			const res = await fetch(`https://api.te-amo.co.za/public/api/assessments/${returnData?.id}/delete`, {
					method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
			});
			setSpinner(false);
			toast.success('Assessment was deleted successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
	}

	useEffect(() => {
	  getInc();
	}, []);

	return(
			<div>
				{spinner?
          <OverlaySpinner />
          :
          ''
        }
				<div className="contentHeader shadow-sm sticky-top">
			        <div className="row">
			            <div className="col-md-10">
			            	<h4 className="TaskContentHeading">Enquiry</h4>
			            </div>

			            <div className="col-md-2">
			                <div className="row">
			                    <div className="col-md-2">
			                      {notify>0?
	                              <div className="containerBadge">
	                                  <NotificationBadge label='!' count={1} effect={Effect.SCALE} />
	                              </div>
	                            :
	                            ''
		                        }
			                    </div>
			                    <div className="col-md-10">
			                        <FontAwesomeIcon icon={faBell} className="icon-top" />
			                    </div>
			                </div>
			            </div>
			        </div>
			    </div>

				<div className="maiSecNave">
    			<div className="row">
    				<div className="col-md-9 mt-1">
      				<Link to="/" className="maiSecNaveLink">Dashboard</Link> > Assessments > <b>ASSESS{id}</b>
      			</div>

      			<div className="col-md-3">
      				<button className="btn btn-dark" onClick={()=>makeJobeCard()} >Make Job Card</button> <span className="btn btn-outline-dark" onClick={()=>deleteJobeCard()}>Delete</span>
      			</div>
    			</div>
    		</div>

				<div className="textArea">
					<div className="container">
						<h5>{returnData?.Title}</h5><br />
	       		<p>{returnData?.Description}</p>
	       	</div><br /><br />

	       	<table class="table table-striped">
					  <thead>
					    <tr>
					      <th scope="col">Item</th>
					      <th scope="col">Type</th>
					      <th scope="col">Qty</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr>
					      <td>DVR</td>
					      <td>{returnData?.DVR_Quantity?returnData?.DVR_Quantity:'0'} channel</td>
					      <td>{returnData?.DVR_Quantity?returnData?.DVR_Quantity:'0'}</td>
					    </tr>
					    <tr>
					      <td>NVR</td>
					      <td>{returnData?.NVR_Quantity?returnData?.NVR_Quantity:'0'} channel</td>
					      <td>{returnData?.NVR_Quantity?returnData?.NVR_Quantity:'0'}</td>
					    </tr>
					    <tr>
					      <td>Dome Cameras</td>
					      <td>{returnData?.Dome_Cameras}</td>
					      <td>{returnData?.Dome_CamerasQty}</td>
					    </tr>
					    <tr>
					      <td>Bullet Cameras</td>
					      <td>{returnData?.Bullet_Cameras}</td>
					      <td>{returnData?.Bullet_CamerasQty}</td>
					    </tr>
					    <tr>
					      <td>Power supply</td>
					      <td>{returnData?.Power_Supply} channel</td>
					      <td>{returnData?.Power_SupplyQty}</td>
					    </tr>
					    <tr>
					      <td>Surveillance Hard Drive</td>
					      <td>{returnData?.Surveillance_Hard_Drive} TB</td>
					      <td>{returnData?.Surveillance_Hard_DriveQty}</td>
					    </tr>
					    <tr>
					      <td>DC Jack(s)</td>
					      <td>Standard</td>
					      <td>{returnData?.DC_Jack_Quantity}</td>
					    </tr>
					    <tr>
					      <td>Baluns</td>
					      <td>Standard</td>
					      <td>{returnData?.Balans_Quantity}</td>
					    </tr>
					    <tr>
					      <td>RJ45 Connector(s)</td>
					      <td>Standard</td>
					      <td>{returnData?.RJ45_Quantity}</td>
					    </tr>
					    <tr>
					      <td>Server Cabinet</td>
					      <td>Standard</td>
					      <td>{returnData?.Server_CabinetQty}</td>
					    </tr>
					    <tr>
					      <td>Network Bridge</td>
					      <td>Standard</td>
					      <td>{returnData?.Network_BridgeQty}</td>
					    </tr>
					    <tr>
					      <td>Access Port</td>
					      <td>Standard</td>
					      <td>{returnData?.Access_PortQty}</td>
					    </tr>
					  </tbody>
					</table>
      	</div>
			</div>
	);
}

export default AssessmentId;