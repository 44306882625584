import TableHeader from '../Tables/TableHeader';
import TableRow from '../Tables/TableRow';
import {Link} from 'react-router-dom';
import { toast } from 'react-toastify';
import Context from '../Context';
import {useContext, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';

const Table = ({text, data, setAssign, setId, stuff, enquiries, open, setData, dataBackUp, setDataBackUp, customers, setSoloCustomer, setSoloJobDetails, setSpinner, spinner, pageLinks, pageNo, setPageNo, setDataTsk, Assessments}) => {

  const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId, sign, setSign] = useContext(Context);
  const [customerName, setCustomerName] = useState();
  const [address, setAddress] = useState();
  const [phone, setPhone] = useState();
  const [thisEmail, setThisEmail] = useState();

  const changeStatus = async (thisId) =>
  {
    //setSpinner(true);
      const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/${thisId}/action`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'tech': 'none'}),
      });



      if (res.ok) {
        //throw new Error('Network response was not ok');

        toast.success('Task was updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/paginateWeb?main=${main}`);
        const data = await res.json();
        setDataTsk(data.data);
      }
      else
      {
        toast.error('Action failed please check network connection!!!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      }
  }

  const seachFocus = () =>
  {
    const seach = document.getElementById('seach');
    //seach.style.border = "1px solid green";
  }

  const showHideFilters = () =>
  {
    const filters = document.getElementById('filters');

    if(filters.style.display === "none")
      filters.style.display = "block";
    else
      filters.style.display = "none";
  }

  const filterByStatus = (value) => {
      setData(value !== "All"?dataBackUp.filter((job) => (job.Status === value) ):dataBackUp);
  }

  const filterByJobType = (value) => {
      setData(value !== "All"?dataBackUp.filter((job) => (job.Task_Type === value) ):dataBackUp);
  }

  const search = (value) =>
  {
    //console.log(dataBackUp);
      setData(value !== ""?dataBackUp.filter((job) => (job.Customer.toLowerCase().includes(value.toLowerCase()) || job.Assigned_to.toLowerCase().includes(value.toLowerCase()) || job.Assigned_by.toLowerCase().includes(value.toLowerCase())) ):dataBackUp);
  }

  const seachSuff = (value) =>
  {
    setData(dataBackUp.filter((job) => (job.Name.toLowerCase().includes(value.toLowerCase()))  ||  (job.Last_Name.toLowerCase().includes(value.toLowerCase()))  ||  (job.Phone_Number.toLowerCase().includes(value.toLowerCase()))  ||  (job.Email.toLowerCase().includes(value.toLowerCase())) ));
  }

  const getStuff = async() => 
  {
    const res = await fetch('https://api.te-amo.co.za/public/api/stuff');
    const data = await res.json();

    //console.log(data);
    //setStuff(data);
  }

  const paginate = async(e, f) => {
    const res = await fetch(`${e}&main=${main}`);
    const data = await res.json();

    setData(data.data);
    setDataBackUp(data.data);
    setPageNo(parseInt(f));
    window.scrollTo(0, 0);
  }

  const getCustomerNew = async(id) => {
      const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${id}`);
      const data = await res.json();
      console.log(data[0].Name);
      setCustomerName(data[0].Name);
      setAddress(data[0].Address);
      setPhone(data[0].Phone_Number);
      setThisEmail(data[0].Email);
      //return data[0].Name;
  }

	return (

		<div className="tableCard2">
      <div className="row">
        <div className="col-9">
          <div className="row">
            <div className="col-md-3">
                <input type="text" placeholder="Search" id="seach" className="myFormInput" onFocus={seachFocus} onChange={stuff||customers?(e)=>seachSuff(e.target.value):(e)=>search(e.target.value)} />
            </div><br /><br />
            {!stuff && !customers && !enquiries && !Assessments?
              <div className="col-md-9">
                  <button className="btn-filter" onClick={showHideFilters}>Filter</button>
              </div>
              :''
            }
          </div>
        </div>

        <div className="col-3">
            <button className="btn-filter">Export</button>
            {stuff?<button className="btn-filter mx-3" onClick={() => open(false)}>add</button>: ''}
        </div>
      </div><br />


      {!stuff && !customers && !enquiries && !Assessments?
      <div id="filters">
        <div className="row">
            <div className="col-md-4">
              <select placeholder="Job status" className="myFormInput" onChange={(e) => filterByStatus(e.target.value)}>
                  <option disabled selected hidden>Job status</option>
                  <option value="All">All</option>
                  <option value="Pending">Booked in</option>
                  <option value="Active">Active</option>
                  <option value="Completed">Completed</option>
              </select><br /><br />
            </div>

            <div className="col-md-4">
              <select placeholder="Job status" className="myFormInput" onChange={(e) => filterByJobType(e.target.value)}>
                  <option disabled selected hidden>Job type</option>
                  <option value="All">All</option>
                  <option value="Installation">Installation</option>
                  <option value="Assessment">Assessment</option>
                  <option value="Call out">Call out</option>
                  {
                    stuff?.map((member) => <option value={member.Name}>{member.Name}</option>)
                  }
              </select>
            </div>

            <div className="col-md-4">

            </div>
        </div>
      </div>
      :''
      }
			<table className="table table-striped mt-2">
        <TableHeader stuff={stuff} customers={customers} enquiries={enquiries} Assessments={Assessments} />

        <tbody>
          {
            (stuff || customers)?data?.map((task) => 
              <TableRow ThisName={task.Name} lastName={task.Last_Name} phoneNumber={task.Phone_Number} thisEmail={task.Email} stuff={stuff} customers={customers} />
            )
            :(enquiries?data?.map((task) => (
               <TableRow ReadOrNot={task.ReadOrNot} enquiries={enquiries} thisId={task.id} company={task.company} nameEnquiries={task.name} lastname={task.lastname} priority={task.priority} phone={task.phone} emailEnquiry={task.email} type={task.type} message={task.message} />
              )):(Assessments?data?.map((task) => (
                <TableRow title={task.Title} Assessments={Assessments} thisId={task.id} Description={task.Description} DVR_Quantity={task.DVR_Quantity} NVR_Quantity={task.NVR_Quantity} Bullet_Cameras={task.Bullet_Cameras} Dome_Cameras={task.Dome_Cameras} Power_Supply={task.Power_Supply} Surveillance_Hard_Drive={task.Surveillance_Hard_Drive} DC_Jack_Quantity={task.DC_Jack_Quantity} Balans_Quantity={task.Balans_Quantity} RJ45_Quantity={task.RJ45_Quantity} Server_Cabinet={task.Server_Cabinet} Network_Bridge={task.Network_Bridge} Access_Port={task.Access_Port} Customer_id={task.Customer_id} Job_id={task.Job_id} main_user={task.main_user} Bullet_CamerasQty={task.Bullet_CamerasQty} Dome_CamerasQty={task.Dome_CamerasQty} Power_SupplyQty={task.Power_SupplyQty} Surveillance_Hard_DriveQty={task.Surveillance_Hard_DriveQty} Server_CabinetQty={task.Server_CabinetQty} Network_BridgeQty={task.Network_BridgeQty} Access_PortQty={task.Access_PortQty} created_at={task.created_at} updated_at={task.updated_at} customerName={customerName} address={address} phoneThis={phone} cusEmail={thisEmail} onClick={()=>getCustomerNew(task.Customer_id)} />
              )):
            data?.map((task) => (task.Status==="Active"?
                <TableRow thisId={task.id} entry="1" title={task.Task_Name} Description_of_job={task.Description_of_job} Intallation_type={task.Intallation_type} category={task.Customer} status={task.Status} AssignedTo={task.Assigned_to} AssignedBy={task.Assigned_by} customerNumber={task.Customer_number} action={(task.Status==="Pending")?"Start" : "Complete"} changeStatus={changeStatus} activeClass={true} completeClass={false} setAssign={setAssign} setId={setId} updated_at={task.updated_at.slice(0, 10)} created_at={task.created_at.slice(0, 10)} setSoloCustomer={setSoloCustomer} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} />: (task.Status==="Completed" 
                  ? <TableRow thisId={task.id} entry="1" title={task.Task_Name} Description_of_job={task.Description_of_job} Intallation_type={task.Intallation_type} category={task.Customer} status={task.Status} AssignedTo={task.Assigned_to} AssignedBy={task.Assigned_by} customerNumber={task.Customer_number} action={(task.Status==="Pending")?"Start" : "Complete"} changeStatus={changeStatus} activeClass={false} completeClass={true} setAssign={setAssign} setId={setId} updated_at={task.updated_at.slice(0, 10)} created_at={task.created_at.slice(0, 10)} setSoloCustomer={setSoloCustomer} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} /> :
                <TableRow thisId={task.id} entry="1" title={task.Task_Name} Description_of_job={task.Description_of_job} Intallation_type={task.Intallation_type} category={task.Customer} status={task.Status} AssignedTo={task.Assigned_to} AssignedBy={task.Assigned_by} customerNumber={task.Customer_number} action={(task.Status==="Pending")?"Start" : "Complete"} changeStatus={changeStatus} activeClass={false} completeClass={false} setAssign={setAssign} setId={setId} updated_at={task.updated_at.slice(0, 10)} created_at={task.created_at.slice(0, 10)} setSoloCustomer={setSoloCustomer} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} />)
                )
            )))
          }
        </tbody>
      </table> <br /><br />


      <nav aria-label="Page navigation example">
        <ul className="pagination">
          {
            pageLinks?.map((e) => (
              <li className={pageNo===parseInt(e.label)?`page-item active`:`page-item`} onClick={()=>paginate(e.url, e.label)}><Link class="page-link" to="#">{e.label}</Link></li>
            ))
          }
        </ul>
      </nav>

		</div>

	);
}

export default Table