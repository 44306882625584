import Context from './Components/Context';
import {useContext, useState} from 'react';

const DataLoada = async(categoty, main) => {
	let data, res;
	//const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

	if(categoty === 'stuff')
	{
		res = await fetch(`https://api.te-amo.co.za/public/api/stuff?main=${main}`);
		data = await res.json();
	}
	else if(categoty === 'customers')
	{
		res = await fetch(`https://api.te-amo.co.za/public/api/customers?main=${main}`);
		data = await res.json();
	}
	else
	{
		res = await fetch(`https://api.te-amo.co.za/public/api/tasks/paginateWeb?main=${main}`,{
		  method: 'GET',
      headers: {
              'Content-Type': 'application/json',
            },
            //body: JSON.stringify({'main': 'test@gmail.com'}),
		});

		data = await res.json();
	}

	//console.log(data);
	
	return data;
}

/*const DataLoada = () => {
	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);
	DataLoada2(main);
}*/

export default DataLoada;