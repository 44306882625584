const TableHeader = ({stuff, customers, enquiries, Assessments}) => {

	return (

		<>
      <thead>
      {
        stuff?
        <tr>
          <th scope="col">#</th>
          <th scope="col">First name</th>
          <th scope="col">Last name</th>
          <th scope="col">Phone</th>
          <th scope="col">Email</th>
        </tr>
        :
        customers?
        <tr>
          <th scope="col">#</th>
          <th scope="col">First name</th>
          <th scope="col">Last name</th>
          <th scope="col">Phone</th>
          <th scope="col">Email</th>
        </tr>
        :
        enquiries?
        <tr>
          <th scope="col">#</th>
          <th scope="col">Enquiry ID</th>
          <th scope="col">Company</th>
          <th scope="col">Name</th>
          <th scope="col">last Name</th>
          <th scope="col">Priority</th>
          <th scope="col">Phone</th>
          <th scope="col">Email</th>
          <th scope="col">Type</th>
        </tr>
        :
        Assessments?
        <tr>
          <th scope="col">#</th>
          <th scope="col">Assess ID</th>
          <th scope="col">Title</th>
          <th scope="col">Description</th>
          <th scope="col">Download</th>
        </tr>
        :
        <tr>
          <th scope="col">#</th>
          <th scope="col">Number</th>
          <th scope="col">Customer</th>
          <th scope="col">Tech</th>
          <th scope="col">Job</th>
          <th scope="col">Start</th>
          <th scope="col">Updated by</th>
          <th scope="col">Updated date</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
      }
      </thead>
		</>

	);
}

export default TableHeader