import {Link} from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';
import Table from './Main/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faBell } from '@fortawesome/free-solid-svg-icons';

import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';

import Context from './Context';

const Enquiries = () => {
	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);
	const [enquiriesState, setEnquiriesState] = useState();
	const [enquiriesStateBackUp, setEnquiriesStateBackUp] = useState();

	const [ notify, setNotify ] = useState();

	const getInc = async() => {
		window.scrollTo(0, 0);
		const res = await fetch(`https://api.te-amo.co.za/public/api/enquiries/get?id=${main}`);
		const data = await res.json();

		const res2 = await fetch(`https://api.te-amo.co.za/public/api/notify/get?id=${main}`);
     	const data2 = await res2.json();

     	setNotify(data2);

		setEnquiriesState(data);
	}

	useEffect(() => {
	  getInc();
	}, []);

	return(
			<div>
				<div className="contentHeader shadow-sm sticky-top">
			        <div className="row">
			            <div className="col-md-10">
			            	<h4 className="TaskContentHeading">Enquiries</h4>
			            </div>

			            <div className="col-md-2">
			                <div className="row">
			                    <div className="col-md-2">
					              {notify>0?
		                              <div className="containerBadge">
		                                  <NotificationBadge label='!' count={1} effect={Effect.SCALE} />
		                              </div>
		                            :
		                            ''
		                          }
			                    </div>
			                    <div className="col-md-10">
			                        <FontAwesomeIcon icon={faBell} className="icon-top" />
			                    </div>
			                </div>
			            </div>
			        </div>
			    </div>


				<div className="maiSecNave"><Link to="/" className="maiSecNaveLink">Dashboard</Link> > <b>Enquiries</b></div>

				<div className="TableArea">
			       <Table enquiries={true} data={enquiriesState} setData={setEnquiriesState} dataBackUp={enquiriesStateBackUp} />
		      </div>
			</div>
	);
}

export default Enquiries;