import Table from './Main/Table';
import {useState, useEffect, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faBell } from '@fortawesome/free-solid-svg-icons';
import {useLoaderData, useNavigation, useOutletContext, Link} from 'react-router-dom';
import Context from './Context';

const Assessments = () => {
	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);
	const [ notify, setNotify ] = useState();
	const [ data, setData ] = useState();
	const [ dataBackUp, setDataBackUp ] = useState();
	const [AssessmentData, setAssessmentData] = useState();

	const getAssessments = async() => {
	    const res = await fetch(`https://api.te-amo.co.za/public/api/assessments/${main}`);
	    const data = await res.json();

	    console.log(data);

	    setAssessmentData(data);
	}

	useEffect(() => {
	    getAssessments();
	}, []);

	return(
		<div>
		    <div className="contentHeader shadow-sm sticky-top">
		        <div className="row">
		            <div className="col-md-10">
		            	<h4 className="TaskContentHeading">Assessments</h4>
		            </div>

		            <div className="col-md-2">
		                <div className="row">
		                    <div className="col-md-2">
		                        {
		                          notify>0?
		                            <div className="containerBadge">
		                                <NotificationBadge label='!' count={1} effect={Effect.SCALE} />
		                            </div>
		                          :
		                          ''
		                        }
		                    </div>
		                    <div className="col-md-10">
		                        <FontAwesomeIcon icon={faBell} className="icon-top" />
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>

	        <div className="maiSecNave"><Link to="/" className="maiSecNaveLink">Dashboard</Link> > <b>Assessments</b></div>

	    	  <div className="TableArea">
		        <Table Assessments={true} data={AssessmentData} setData={setData} dataBackUp={dataBackUp} />
	          </div>
	    	{/*<Scheduler />*/}
	    </div>
	)
}

export default Assessments;