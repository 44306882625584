import {useLoaderData, useNavigation, useOutletContext, Link, Outlet} from 'react-router-dom';
import Table from './Main/Table';
import {useState, useEffect, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faCog, faBell } from '@fortawesome/free-solid-svg-icons';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';

import Context from './Context';

function TaskContent({setSpinner, spinner, setSoloCustomer, data, setData, setDataTsk}) {

  const [date, setDate] = useState('');
  const tasksOriginal = useLoaderData();

  const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId, sign, setSign] = useContext(Context);

  const [tasks, setTasks, tasksBckUp, setTasksBckUp, assign, setAssign, id, setId, soloJobDetails, setSoloJobDetails] = useOutletContext(useLoaderData());
  //const [tasksBckUp, setTasksBckUp] = useOutletContext(useLoaderData());

  //const [data, setData] = useState(tasksOriginal.data);
  const [dataBackUp, setDataBackUp] = useState(tasksOriginal.data);
  const [pageLinks, setPageLinks] = useState(tasksOriginal.links);
  const [pageNo, setPageNo] = useState(tasksOriginal.current_page)

  const [ notify, setNotify ] = useState();

  //setTasks(useLoaderData());

  const loadDataFunc = async() => {
    setData(tasksOriginal.data);
    window.scrollTo(0, 0);
    const res = await fetch(`https://api.te-amo.co.za/public/api/notify/get?id=${main}`);
    const data = await res.json();

    setNotify(data);
  }

  useEffect(() => {
      loadDataFunc();
  }, []);

  const tasksNav = useNavigation();

  //console.log(soloCustomer);

  if(tasksNav.state === 'loading')
  {
  	return(
      <div className="TableArea">
  		  <SkeletonTheme baseColor="#dfdfdf" highlightColor="#afafaf" width="7%">
          <p>
            
            <Skeleton count={1} height="30px"/><br /><br /><br />

            <div className="row">
              <div className="col-md-6" >
                <Skeleton count={1} height="150px" width="100%"/>
              </div>
              <div className="col-md-6" >
                <Skeleton count={1} height="150px" width="100%"/>
              </div><br /><br /><br /><br /><br /><br />
              <div className="col-md-6" >
                <Skeleton count={1} height="150px" width="100%"/>
              </div>
            </div>
          </p>
        </SkeletonTheme>
      </div>
  	)
  }


  const subHandle = async (e) => {
      e.preventDefault();
      console.log({'date': date})
      const res = await fetch('https://api.te-amo.co.za/public/api/tasks', {

        method: 'POST',
        headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({'date': date}),

      })


      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json(); // Parse response JSON if expected

      // Handle the data received from the server
      
      setTasks(data);
      setTasksBckUp(data);
      console.log(tasksBckUp);

  }

  const test = async (e) => {
      e.preventDefault();

      let name = document.getElementById('name');
      let email = document.getElementById('email');
      let password = document.getElementById('password');

      const res = await fetch('https://api.te-amo.co.za/public/api/register', {

          method: 'POST',
          headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({'name': name.value, 'email': email.value, 'password': password.value}),
        })


        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }

        const data = await res.json();

        console.log(data);
  }
  return (
    <div>
        <div className="contentHeader shadow-sm sticky-top">
          <div className="row">
              <div className="col-md-10">
                <h4 className="TaskContentHeading">Jobs</h4>
              </div>

              <div className="col-md-2">
                  <div className="row">
                      <div className="col-md-2">
                          {notify>0?
                              <div className="containerBadge">
                                  <NotificationBadge label='!' count={1} effect={Effect.SCALE} />
                              </div>
                            :
                            ''
                          }
                      </div>
                      <div className="col-md-10">
                          <FontAwesomeIcon icon={faBell} className="icon-top" />
                      </div>
                  </div>
              </div>
          </div>
      </div>

        <div className="maiSecNave"><Link to="/" className="maiSecNaveLink">Dashboard</Link> > <b>Jobs</b></div>

        {//<Outlet />
        }

        <div className="TableArea">
          <Table data = {data} pageLinks={pageLinks} setData={setData} dataBackUp={dataBackUp} pageNo={pageNo} setPageNo={setPageNo} setDataBackUp={setDataBackUp} setAssign={setAssign} setId={setId} setSoloCustomer={setSoloCustomer} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} setDataTsk={setDataTsk} />
        </div>
        
    </div>
  );
}

export default TaskContent;