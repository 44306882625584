import {Link, useOutletContext} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Logo from '../../Capture2.PNG';
import Context from '../Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faBell, faPaperclip } from '@fortawesome/free-solid-svg-icons';

const TableRow = ({title, category, deadline, entry, action, status, changeStatus, thisId, activeClass, completeClass, setAssign, setId, updated_at, created_at, AssignedBy, AssignedTo, customerNumber, ThisName, lastName, phoneNumber, thisEmail, stuff, customers, setSoloCustomer, setSoloJobDetails, Description_of_job, Intallation_type, spinner, setSpinner,
  //Enquiries
  company, nameEnquiries, lastname, priority, phone, emailEnquiry, type, message, enquiries, ReadOrNot,          Assessments, Description, DVR_Quantity, NVR_Quantity, Bullet_Cameras, Dome_Cameras, Power_Supply, Surveillance_Hard_Drive, DC_Jack_Quantity, Balans_Quantity, RJ45_Quantity, Server_Cabinet, Network_Bridge, Access_Port, Customer_id, Job_id, Bullet_CamerasQty, Dome_CamerasQty, Power_SupplyQty, Surveillance_Hard_DriveQty, Server_CabinetQty, Network_BridgeQty, Access_PortQty, customerName, address, phoneThis, cusEmail, onClick
}) => {

  //const [tasks, setTasks, tasksBckUp, setTasksBckUp, assign, setAssign, id, setId, soloCustomer, setSoloCustomer] = useOutletContext();
  const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId, sign, setSign] = useContext(Context);

  const handleAssign = (currentStatus, currentThisId) => {
    //console.log(spinner);
    //setSpinner(true);
    //console.log(spinner);
    if(currentStatus === "Pending")
    {
      setAssign(false); setId(currentThisId);
    }
    else
    {
      //console.log(spinner);
      changeStatus(currentThisId);
     //setSpinner(false);
    }
    
  }

  const getCustomer = async(id, id2) => {

      const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${id}`);
      const data = await res.json();

      const res2 = await fetch(`https://api.te-amo.co.za/public/api/tasks/${id2}`);
      const data2 = await res2.json();

      setSoloCustomer(data);
      //console.log(data);

      setSoloJobDetails(data2);
      setJobId(data2.id);
      localStorage.setItem("jobId", JSON.stringify(data2.id));
      console.log(id2)
      //return data;

      
      const response = await fetch(`https://api.te-amo.co.za/public/api/tasks/view/sign/${id2}`);
      const contentType = response.headers.get("content-type");
      const blob = await response.blob();
      
      const reader = new FileReader();
      reader.onloadend = () => {
        setSign(reader.result);
      };
      reader.readAsDataURL(blob);
  }

  const Assessment = async() => {
      const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${Customer_id}`);
      const data = await res.json();

      var doc = new jsPDF('p', 'pt');
      doc.addImage(Logo, 'JPEG', 40, 10 );

      doc.setFont('helvetica', 'normal'); // Set font and style
      doc.setFontSize(9); // Set font size
      doc.text('Te-Amo Business Solutions', 440, 20);
      doc.text('2 Nelson Crescent, Malvern, Queensburgh, 4055', 355, 30);
      doc.text('0314633124', 500, 40);
      doc.text('help@te-amo.co.za', 472, 50);
      doc.text('Company No.: 2016/163396/07', 425, 60);
      doc.text('VAT Reg. No.: 4900275571', 440, 70);

      doc.setFont('helvetica', 'bold'); // Set font and style
      doc.setFontSize(16);
      doc.text('Assessment', 255, 110);

      doc.setFont('helvetica', 'normal'); // Set font and style
      doc.setFontSize(9);

      autoTable(doc, {
        head: [
          [
            {
              content: 'Assessment',
              colSpan: 4,
              styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
            },
          ],
        ],
        body: [
          ['CUSTOMER NAME:', data[0].Name, 'CUSTOMER CONTACT:', data[0].Phone_Number],
          ['', '', '', ''],
          ['CUSTOMER ADDRESS:', data[0].Address, 'CUSTOMER EMAIL:', data[0].Email],
          ['', '', '', ''],
          ['', '', 'ASSESSED BY:', ''],
        ],
        theme: 'grid',
        startY: 120,
        columnStyles: { 0: { cellWidth: 125, fontStyle: 'bold' }, 1: { cellWidth: 'auto' }, 2: { cellWidth: 125, fontStyle: 'bold' }, 3: { cellWidth: 'auto' } }
      })

      doc.autoTable({
        head: [
          [
            {
              content: 'Work Scope',
              colSpan: 1,
              styles: { halign: 'center', fillColor: [220, 220, 220], textColor: [40, 40, 40], fontStyle: 'normal' },
            },
          ],
        ],
        body: [
                [Description],
              ],
        startY: doc.lastAutoTable.finalY + 20,
        theme: 'grid',
      });

      let qntyDVR, qntyNVR, newHD;

      if(!DVR_Quantity)
      {
        qntyDVR = 0;
      }
      else
      {
        qntyDVR = 1;
      }
      if(!NVR_Quantity)
      {
        qntyNVR = 0;
      }
      else
      {
        qntyNVR = 1;
      }
      if(Surveillance_Hard_DriveQty == 0)
      {
        newHD = 0;
      }
      else
      {
        newHD = `${Surveillance_Hard_Drive} TB, ${Surveillance_Hard_DriveQty}`;
      }

      autoTable(doc, {
        head: [['Item', 'Type', 'Qty']],
        body: [
                ['DVR', `${DVR_Quantity} channel`, qntyDVR],
                ['NVR', `${NVR_Quantity} channel`, qntyNVR],
                ['Dome Cameras', `${Dome_Cameras}`, Dome_CamerasQty],
                ['Bullet Cameras', `${Bullet_Cameras}`, Bullet_CamerasQty],
                ['Power supply', `${Power_Supply} channel`, Power_SupplyQty],
                ['Surveillance Hard Drive', `${Surveillance_Hard_Drive} TB`, newHD],
                ['DC Jack(s)', 'Standard', DC_Jack_Quantity],
                ['Baluns', 'Standard', Balans_Quantity],
                ['RJ45 Connector(s)', 'Standard', RJ45_Quantity],
                ['Server Cabinet', 'Standard', Server_CabinetQty],
                ['Network Bridge', 'Standard', Network_BridgeQty],
                ['Access Port', 'Standard', Access_PortQty],
              ],
        theme: 'grid',
        startY: doc.lastAutoTable.finalY + 20,
        columnStyles: { 0: { cellWidth: 'auto', fontStyle: 'bold' }, 1: { cellWidth: 'auto' }, 2: { cellWidth: 125, fontStyle: 'bold' }, 3: { cellWidth: 'auto' } },
        headStyles: {
          fillColor: [220, 220, 220], // black background
          textColor: [40, 40, 40], // white text
          fontStyle: 'bold',
          halign: 'center' // horizontal alignment
        }
      });

      doc.save('table.pdf');
  }

	return (
		<>
            {
              stuff||customers?
              <tr className={`${activeClass ? 'taskActive' : (completeClass ? 'taskComplete' : '')} `}>
                <td scope="row">#</td>
                <td scope="row">{ThisName}</td>
                <td scope="row">{lastName}</td>
                <td>{phoneNumber}</td>
                <td scope="row">{thisEmail}</td>
              </tr>
              :
              enquiries?
              <tr className={parseInt(ReadOrNot)?'table-primary':''}>
                <td scope="row"><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                <td scope="row"><Link to={`/enquiries/${thisId}`} className="myNav" >ENQ{thisId}</Link></td>
                <td scope="row">{company}</td>
                <td scope="row">{nameEnquiries}</td>
                <td scope="row">{lastname}</td>
                <td scope="row">{priority}</td>
                <td scope="row">{phone}</td>
                <td scope="row">{emailEnquiry}</td>
                <td scope="row">{type}</td>
                <td scope="row">{ThisName}</td>
              </tr>
              :
              Assessments?
              <tr className='' onClick={onClick}>
                <td scope="row">#</td>
                <td scope="row"><Link to={`/assessments/${thisId}`} className="myNav" >ASSES{thisId}</Link></td>
                <td scope="row">{title}</td>
                <td scope="row">{Description.slice(0,100)}...</td>
                <td scope="row">
                  <div className="row">
                    <div className="col-md-12">
                      <button className="btn btn-success btn-sm" onClick={() => Assessment()}>Download</button>
                    </div>
                    <div className="col-md-2">
                      {/*<FontAwesomeIcon icon={faPaperclip} />*/}
                    </div>
                  </div>
                </td>
              </tr>
              :
              <tr className={`${activeClass ? 'taskActive' : (completeClass ? 'taskComplete' : '')} `}>
                <td scope="row">#</td>
                <td scope="row"><Link to={`/tasks/${customerNumber}/customer_details`}  className="myNav" onClick={() => getCustomer(customerNumber, thisId)}>JB{thisId}</Link></td>
                <td>{category}</td>
                <td scope="row">{AssignedTo!='none'?JSON.parse(AssignedTo)?.map((stuff) => (<span>{stuff.label}, </span>)):''}</td>
                <td>{title?title:(Description_of_job?Description_of_job.slice(0, 35):(Intallation_type?Intallation_type:''))}</td>
                <td scope="row">{created_at}</td>
                <td scope="row">{AssignedBy}</td>
                <td scope="row">{updated_at}</td>
                <td><button className="badge badge-danger text-dark">{status} </button></td>
                <td><button className="btn btn-success"  disabled={completeClass? true : false}  onClick={() => handleAssign(status, thisId)} >{action} </button></td>
              </tr>
            }
		</>

	);
}

export default TableRow