import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.js';
import { createBrowserRouter, createRoutesFromElements, Route, Outlet, RouterProvider} from 'react-router-dom';
import { useState, createContext, useEffect } from 'react';
import Sidebar from './Components/Sidebar';
import Cards from './Components/Main/Cards';
import Table from './Components/Main/Table';
import Modal from './Components/Modal';
import Deadlines from './Components/Deadlines';
import Home from './Components/Home';
import Tasks from './Components/Tasks';
import TasksCat from './Components/TasksCat';
import TaskContent from './Components/taskContent';
import Register from './Components/Register';
import UpdatePass from './Components/UpdatePass';
import Login from './Components/Login';
import Inoives from './Components/Inoives';
import Stuff from './Components/Stuff';
import Customers from './Components/Customers';
import Assessments from './Components/Assessments';
import Enquiries from './Components/Enquiries';
import Enquiry from './Components/Enquiry';
import AssessmentId from './Components/AssessmentId';
import Appointments from './Components/appointments';
import JobDetails from './Components/pages/JobDetails';
import Err from './Components/Err';
import SoloJob from './Components/soloJob';
import Attachments from './Components/Attachments';
import Context from './Components/Context';
import Root from './Components/Root';
import MainDocType from './Components/MainDocType';
import RootTwo from './Components/RootTwo';
import DataLoada from './DataLoada.js';
import DataLoadaInt from './DataLoadaInt.js';
import pandingDataLoada from './Components/data/pending.js';
import { ToastContainer } from 'react-toastify';
import echo from './echo';


function App() {

  useEffect(() => {
      const channel = echo.channel('public');

      channel.listen('job_notifications', (event) => {
          console.log('Event received:', event);
          // Handle the event data
      });

      return () => {
          // Unsubscribe when the component unmounts
          channel.stopListening('job_notifications');
      };
  }, []);

  const loginData = {
      'logegdin': false
  }

  const getLoginData = JSON.parse(localStorage.getItem('logedIn2'));

  const tokenLS = JSON.parse(localStorage.getItem('token'));
  const nameLS = JSON.parse(localStorage.getItem('name'));
  const emailLS = JSON.parse(localStorage.getItem('email'));
  const mainLS = JSON.parse(localStorage.getItem('main'));
  const userLS = JSON.parse(localStorage.getItem('user'));
  const jobIdLS = JSON.parse(localStorage.getItem('jobId'));
  const cusIdLS = JSON.parse(localStorage.getItem('cusId'));

  
  const [logedIn, setLogedIn] = useState(getLoginData);
  console.log(getLoginData);
  //localStorage.setItem("logedIn", JSON.stringify(false));
  const [token, setToken] = useState(tokenLS);
  const [name, setName] = useState(nameLS);
  const [email, setEmail] = useState(emailLS);
  const [main, setMain] = useState(mainLS);
  const [user, setUser] = useState(userLS);
  const [soloCustomer, setSoloCustomer] = useState();
  const [soloJobDetails, setSoloJobDetails] = useState();
  const [spinner, setSpinner] = useState(false);

  const [cusId, setCusId] = useState(cusIdLS);
  const [jobId, setJobId] = useState(jobIdLS);

  const [ data, setData ] = useState();
  const [ dataTsk, setDataTsk ] = useState();
  const [ sign, setSign ] = useState(null);

  

  //const Context = createContext();
  const router = createBrowserRouter(
      createRoutesFromElements(
          <Route path="/" element={<Root data={data} setData={setData} dataTsk={dataTsk} setDataTsk={setDataTsk}  soloCustomer={soloCustomer} setSoloCustomer={setSoloCustomer} soloJobDetails={soloJobDetails} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} />} >
              <Route index element={<Home />} loader={()=>DataLoadaInt('', main)} />
              <Route path="/deadlines" element={<Deadlines />} />
              <Route path="/tasks" element={<TaskContent data={dataTsk} setData={setDataTsk} setDataTsk={setDataTsk} Title="All Tasks" spinner={spinner} setSpinner={setSpinner} setSoloCustomer={setSoloCustomer} />} loader={()=>DataLoada('',main)} />
                <Route path='/tasks/id' element={<SoloJob Title="All Tasks" soloCustomer={soloCustomer} setSoloCustomer={setSoloCustomer} />} loader={DataLoada} />
                <Route path='/tasks/:idReal/customer_details' element={<SoloJob Title="All Tasks" soloJobDetails={soloJobDetails} soloCustomer={soloCustomer} setSoloCustomer={setSoloCustomer} setSpinner={setSpinner} />} loader={DataLoada} />
                <Route path='/tasks/:id/job_details' element={<JobDetails Title="All Tasks" soloJobDetails={soloJobDetails} setSoloJobDetails={setSoloJobDetails} spinner={spinner} setSpinner={setSpinner} />} loader={DataLoada} />
                <Route path='/tasks/:id/attachments' element={<Attachments Title="All Tasks" soloJobDetails={soloJobDetails} setSoloCustomer={setSoloCustomer} />} loader={DataLoada} />
                <Route path='/tasks/id/appointments' element={<SoloJob Title="All Tasks" soloCustomer={soloCustomer} setSoloCustomer={setSoloCustomer} />} loader={DataLoada} />
                <Route path='/tasks/id/summary' element={<mainDocType Title="All Tasks" soloCustomer={soloCustomer} setSoloCustomer={setSoloCustomer} />} loader={DataLoada} />
              
                <Route path="/tasks/all" element={<TaskContent Title="All Tasks" />} loader={DataLoada} />
                <Route path="/tasks/pending" element={<TaskContent Title="All Tasks" />} loader={()=>DataLoada('Pending')} />
                <Route path="/tasks/active" element={<TaskContent Title="All Tasks" />} loader={()=>DataLoada('Active')} />
                <Route path="/tasks/completed" element={<TaskContent Title="All Tasks" />} loader={()=>DataLoada('Completed')} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
            
              

              <Route path="/categories" element={<TasksCat />} loader={()=>DataLoadaInt('cat')} />
                <Route path="/categories/all" element={<TaskContent />} loader={DataLoada} />
                <Route path="/categories/tech" element={<TaskContent />} loader={()=>DataLoada('Tech')} />
                <Route path="/categories/marketing" element={<TaskContent />} loader={()=>DataLoada('Marketing')} />
                <Route path="/categories/admin" element={<TaskContent />} loader={()=>DataLoada('Admin')} />
              
              
              <Route path="/invoices" element={<Inoives />} />
              <Route path="/customers" element={<Customers data={data} setData={setData} />} loader={()=>DataLoada('customers', main)} />
              <Route path="/stuff" element={user=='owner'?<Stuff />:<Err />} loader={()=>DataLoada('stuff', main)} />
              <Route path="/enquiries" element={<Enquiries />} />
              <Route path="/assessments" element={<Assessments />} />
              <Route path="/assessments/:id" element={<AssessmentId />} />
              <Route path="/enquiries/:id" element={<Enquiry />} />
              <Route path="/appointments" element={<Appointments />} />
          </Route>
      )
  )

  const router2 = createBrowserRouter(

      createRoutesFromElements(

        <Route path="/" element=<RootTwo /> >
          <Route index element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/:id/password/update" element={<UpdatePass />} />
        </Route>

      )
  )

  
    

  return (
    <>
      {logedIn?
      <Context.Provider value={[logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId, sign, setSign]}>
        <>
          <RouterProvider router={router} />
          <ToastContainer />
        </>
      </Context.Provider>
        :
        <Context.Provider value={[logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId, sign, setSign]}>
        <>
          <RouterProvider router={router2} />
          <ToastContainer />
        </>
        </Context.Provider>
      }
    </>
  );
}

export default App;



